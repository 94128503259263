export function skillIdReg():RegExp{
    return /^TM-[A-Z]{1}[0-9]{4}00$/;
}
export function workingCategoryIdReg():RegExp{
    return /^TM-[A-Z]{1}[0-9]{4}[0-9]{2}(?<!00)$/;
}
export function TWPhoneReg():RegExp{
    return /^09[0-9]{8}$/;
}
export function emailReg():RegExp{
    return /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
}
export function dateReg():RegExp{
    return /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
}
export function timeReg():RegExp{
    return /^[0-9]{2}:[0-9]{2}:[0-9]{2}$/;
}
export function ageReg():RegExp{
    return /^[0-9]+$/;
}
export function numReg():RegExp{
    return /^[0-9]+$/;
}