import * as regex from '@/models/Regex/Regex';

export function isEmpty(input:string):boolean{
    return input == '';
}
export function validateSkillId(input:string):boolean{
    return regex.skillIdReg().test(input);
}
export function validateWorkingCategoryId(input:string):boolean{
    return regex.workingCategoryIdReg().test(input);
}
export function validateTWPhoneNum(input:string):boolean{
    return regex.TWPhoneReg().test(input);
}
export function validateEmail(input:string):boolean{
    return regex.emailReg().test(input);
}
export function validateDate(str:string):boolean{
    return regex.dateReg().test(str);
}
export function validateTime(str:string):boolean{
    return regex.timeReg().test(str);
}
export function validateAge(age:string):boolean{
    return regex.ageReg().test(age);
}
export function validateNum(num:string):boolean{
    return regex.numReg().test(num);
}