
import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import Loading from "@/components/Utility/Loading.vue";

import {validateTWPhoneNum} from '@/models/Validator/Validator';

@Component({
  components: {
    Loading,
  },
})
export default class AcademyParticipantForm extends Vue {
  // props
  @Prop() public isLoading!: boolean;
  @Prop() public send!: any;

  // data
  postData = require("@/assets/post.json");
  name = "";
  phone = "";
  citySelect: number | null = null;
  selectedDistrict: number | null = null;
  addressStr = "";
  sourceSelected = [];
  isWarningShow = false;
  checkState: boolean[] = [true, true, true, true];

  //hook

  //methods
  async createCourseWish(): Promise<void> {
    const sourceStr = this.sourceSelected.join(',');
    const needToCheck = [this.name, this.phone, this.addressStr, sourceStr];

    for(let i = 0; i < needToCheck.length; i++){
      this.checkState[i] = Boolean(needToCheck[i]);
    }

    if(this.checkState.includes(false)){
      this.isWarningShow = true;
      document.documentElement.scrollTop = 0;     
      return; 
    }        

    try{
      //待補sdk
      // await tigermaster.activityService.xxx({
        // name:this.name,
        // phone:this.phone,
        // address:this.addressStr,
        // source:sourceStr
      // })

    }finally{
      this.send();
    }
  }
  validator(index:number, val:string | boolean):void{
    switch(index){
      case 1:
        this.checkState[1] = validateTWPhoneNum(val as string);
        break;

      default:
        this.checkState[index] = Boolean(val);
    }
  }      

  //computed
  get cities(): string[] {
    return this.postData.map((item: any) => {
      return item.name;
    });
  }
  get districts(): { name: string; zip: string }[] {
    let districts: { name: string; zip: string }[] = [];
    if (this.citySelect !== null) {
      districts = this.postData[this.citySelect].districts;
    }
    return districts;
  }

  // watch
  @Watch("citySelect")
  private watchcitySelect() {
    let selected = document.querySelector(".address-select") as HTMLElement;
    selected.style.color = "#000";
    this.selectedDistrict = null;
    if (
      (this.selectedDistrict as unknown as number) >=
      this.postData[this.citySelect as number].districts.length
    ) {
      this.selectedDistrict = 0;
    }
  }
  @Watch("selectedDistrict")
  private watchdisSelect() {
    let selected = document.querySelector(".dis-select") as HTMLElement;
    selected.style.color = "#000";
    this.addressStr =
      this.cities[this.citySelect as number] +
      this.districts[this.selectedDistrict as number].name;
  }
  @Watch('sourceSelected')
  watchSourceSelected():void{
      this.checkState[3] = this.sourceSelected.length > 0;
  }  
}
